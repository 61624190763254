<template>
  <div class="component-wrapper">
    <b-tabs fill>
      <b-tab title="Prompts">
        <PromptList />
      </b-tab>
      <b-tab title="Variables">
        <VariableList />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import PromptList from "./components/PromptList.vue";
import VariableList from "./components/VariableList.vue";

export default {
  name: "PromptMain",
  components: {
    PromptList,
    VariableList,
  },
};
</script>
