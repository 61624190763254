<template>
  <div>
    <vue-element-loading :active="loader"></vue-element-loading>
    <b-card>
      <template #header>
        <b-button class="ml-auto" variant="primary" @click.stop="showDetail()">New Variable</b-button>
      </template>
      <b-table :items="table.items" :fields="table.fields" small fixed striped hover>
        <template #cell(action)="data">
          <b-button variant="warning" size="sm" @click.stop="showDetail(data.item)">Edit</b-button>
          <b-button variant="danger" size="sm" class="ml-1" @click.stop="remove(data.item)">Delete</b-button>
        </template>
      </b-table>
    </b-card>
    <b-modal v-model="modal" lazy centered hide-footer no-close-on-backdrop no-close-on-esc @hide="selectedVariable = null">
      <variable-detail :variable="selectedVariable" @onSaveSuccess="saveSuccess" />
    </b-modal>
  </div>
</template>

<script>
import api from "@api";
import VariableDetail from "./VariableDetail.vue";

export default {
  name: "VariableList",
  components: {
    VariableDetail,
  },
  data() {
    return {
      table: {
        items: [],
        fields: [
          { key: "key", label: "Key" },
          { key: "options", label: "Options" },
          { key: "action", label: "", class: "text-right" },
        ],
      },
      selectedVariable: null,
      loader: false,
      modal: false,
    };
  },
  async created() {
    await this.loadVariables();
  },
  methods: {
    async loadVariables() {
      try {
        this.table.items = await api.get(`/prompt/variables`);
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    },
    async showDetail(rec) {
      if (!!rec) this.selectedVariable = { ...rec };
      this.modal = true;
    },
    async remove(rec) {
      try {
        await api.delete(`/prompt/variables/${rec.id}`);
        this.loadVariables();
      } catch (error) {
        this.showToast(`error`, error);
      }
    },
    saveSuccess() {
      this.modal = false;
      this.loadVariables();
    },
  },
};
</script>
