<template>
  <div>
    <b-card>
      <template #header>
        <b-button class="ml-auto" variant="primary" @click.stop="showDetail()">New Prompt</b-button>
      </template>
      <template #default>
        <vue-element-loading :active="loader"></vue-element-loading>
        <b-jumbotron v-for="x in promptList" :key="x.id">
          <template #default>
            <small class="text-primary"><b>Title Prompt</b></small>
            <p>{{ x.titlePrompt }}</p>
            <small class="text-primary"><b>Content Prompt</b></small>
            <p>{{ x.contentPrompt }}</p>
            <b-button variant="warning" size="sm" @click.stop="showDetail(x)">Edit</b-button>
            <b-button variant="danger" size="sm" class="ml-1" @click.stop="remove(x)">Delete</b-button>
          </template>
        </b-jumbotron>
      </template>
    </b-card>
    <b-modal v-model="modal" size="xl" centered hide-footer no-close-on-backdrop no-close-on-esc @hide="selectedPrompt = null">
      <prompt-detail :prompt="selectedPrompt" @onSaveSuccess="saveSuccess" />
    </b-modal>
  </div>
</template>

<script>
import api from "@api";
import PromptDetail from "./PromptDetail.vue";

export default {
  name: "PromptList",
  components: {
    PromptDetail,
  },
  data() {
    return {
      promptList: [],
      selectedPrompt: null,
      modal: false,
      loader: false,
    };
  },
  async created() {
    await this.loadPrompts();
  },
  methods: {
    async loadPrompts() {
      this.loader = true;
      try {
        this.promptList = await api.get(`/prompt`);
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    },
    showDetail(prompt) {
      if (!!prompt) this.selectedPrompt = { ...prompt };
      this.modal = true;
    },
    saveSuccess() {
      this.modal = false;
      this.loadPrompts();
    },
    async remove(rec) {
      try {
        let confirmed = await this.showConfirm(`Are you sure want to delete this prompt?`);
        if (!confirmed) return;
        await api.delete(`/prompt/${rec.id}`);
        this.loadPrompts();
      } catch (error) {
        this.showToast(`error`, error);
      }
    },
  },
};
</script>
