<template>
  <div>
    <b-form>
      <b-form-group label="Key" label-for="key">
        <b-form-input id="key" v-model="payload.key" :disabled="loader" required></b-form-input>
      </b-form-group>
      <b-form-group label="Options" label-for="options">
        <b-form-tags v-model="payload.options" placeholder="Enter new options" duplicate-tag-text="Duplicate Options" remove-on-delete></b-form-tags>
      </b-form-group>
    </b-form>
    <hr />
    <div class="d-flex">
      <b-button :disabled="loader" class="ml-auto" variant="success" @click="saveVariable"> Save </b-button>
    </div>
  </div>
</template>

<script>
import api from "@api";

export default {
  name: "VariableDetail",
  props: {
    variable: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      payload: {
        key: null,
        options: [],
      },
      loader: false,
    };
  },
  async mounted() {
    if (this.variable) {
      this.payload = this.variable;
      this.payload.options = this.payload.options.split(",");
    }
  },
  methods: {
    async saveVariable() {
      this.loader = true;
      try {
        this.payload.options = this.payload.options.join(",");
        if (this.variable) {
          await api.put(`/prompt/variables/${this.variable.id}`, this.payload);
        } else {
          await api.post(`/prompt/variables`, this.payload);
        }
        this.$emit("onSaveSuccess");
      } catch (error) {
        this.showToast("error", error);
      } finally {
        this.loader = false;
      }
    },
  },
};
</script>
