<template>
  <div>
    <b-row>
      <b-col cols="9">
        <b-form>
          <b-form-group label="Title" description="">
            <template #description>
              <small class="text-danger">Mandatory variable: Anchor</small>
            </template>
            <b-form-textarea v-model="payload.titlePrompt" trim rows="3" max-rows="5" />
          </b-form-group>
          <b-form-group label="Content">
            <template #description>
              <small class="text-danger">Mandatory variable: Anchor, TargetUrl, Title</small>
            </template>
            <b-form-textarea v-model="payload.contentPrompt" trim rows="10" max-rows="25" />
          </b-form-group>
        </b-form>
        <hr />
        <div class="d-flex">
          <span class="text-danger">Variables is needed in precise casing, and wrapped inside {}, EG: {Anchor}, {TargetUrl}, {MyCustomVariable}</span>
          <b-button variant="primary" class="ml-auto" @click="savePrompt">Save</b-button>
        </div>
      </b-col>
      <b-col cols="3">
        Available Variables
        <b-list-group>
          <b-list-group-item v-for="x in variableList" :key="x.key">
            {{ x.key }}
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import api from "@api";
export default {
  name: `PromptDetail`,
  props: {
    prompt: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      payload: {
        titlePrompt: null,
        contentPrompt: null,
      },

      loader: false,
      variableList: [],
    };
  },
  async mounted() {
    await this.loadVariables();
    if (this.prompt) {
      this.payload = this.prompt;
    }
  },
  methods: {
    async loadVariables() {
      try {
        this.variableList = await api.get(`prompt/variables`);
      } catch (error) {
        this.showToast("error", error);
      }
    },
    async savePrompt() {
      this.loader = true;
      try {
        if (this.prompt) {
          await api.put(`/prompt/${this.prompt.id}`, this.payload);
        } else {
          await api.post(`/prompt`, this.payload);
        }
        this.$emit("onSaveSuccess");
      } catch (error) {
        this.showToast("error", error);
      } finally {
        this.loader = false;
      }
    },
  },
};
</script>
